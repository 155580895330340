import React from 'react';
import ApiTable from '../shared/api-table/api-table.component';

import { getTableConfig } from './coupon-table.config.js';
import { COUPON_LIST } from '../../constants/apiPath';

const CouponTable = ({
  giftUUID = '',
  onSelect,
  onDelete,
  onCouponUsersDownload,
}) => {
  return (
    <ApiTable
      url={`${COUPON_LIST}/${giftUUID}`}
      getTableConfig={getTableConfig}
      defaultOrder={'ascend'}
      defaultOrderField={'createdAt'}
      defaultFilterField={'createdAt'}
      defaultFilterValue={''}
      size={'middle'}
      actions={{
        onSelect,
        onDelete,
        onCouponUsersDownload,
      }}
    />
  );
};

export default CouponTable;
