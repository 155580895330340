import React from 'react';
import ListAddCard from '../shared/list-add-card/list-add-card.component';
import QuestionInfoCard from '../shared/question-info-card/question-info-card.component';

import './question-list.style.css';

const QuestionList = ({
  handleAdd = () => {},
  questions = [],
  handleEditClick = () => {},
  handleDeleteClick = () => {},
  handleOrderClick = () => {},
  handleJumpClick = () => {},
  handleSurveyCloseClick = () => {},
  handleSurveyCloseForSubQuestionClick = () => {},
}) => {
  return (
    <div className="question-cards" data-testid="question-list">
      <div className="question-card">
        <ListAddCard onClick={handleAdd} />
      </div>
      {questions.map(question => (
        <div className="question-card" key={question.uuid}>
          <QuestionInfoCard
            onClick={() => {}}
            question={{
              ...question,
              answers: question.answers.sort((a, b) => a.order - b.order),
            }}
            questionList={questions}
            onEditClick={handleEditClick}
            onDeleteClick={handleDeleteClick}
            onOrderClick={handleOrderClick}
            onJumpClick={handleJumpClick}
            onSurveyCloseClick={handleSurveyCloseClick}
            onSurveyCloseForSubQuestionClick={
              handleSurveyCloseForSubQuestionClick
            }
          />
        </div>
      ))}
    </div>
  );
};

export default QuestionList;
