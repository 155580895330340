import React from 'react';
import { Typography, Button } from 'antd';
import { withRouter } from 'react-router';
import { apiPut } from '../../../utils/api';
import { USER_FILTER } from '../../../constants/apiPath';

import FilterTable from '../../../components/filter-table/filter-table.component';

import './filter-list.style.css';

const { Title } = Typography;

const FilterListPage = ({ history }) => {
  const onDelete = async ({ uuid, tableData, setTableData }) => {
    try {
      await apiPut({
        url: `${USER_FILTER}/delete/${uuid}`,
        showNotificaton: true,
      });
      setTableData(tableData.filter(data => data.uuid !== uuid));
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="dashboard-filter-page">
      <div className="dashboard-filter-page__header">
        <div className="dashboard-filter-page__header-title">
          <Title level={2}>Szűrők</Title>
        </div>
        <div className="dashboard-filter-page__header-btn">
          <Button type="dashed" onClick={() => history.push('filters/new')}>
            Szűrő hozzáadása
          </Button>
        </div>
      </div>
      <div className="dashboard-filter-page__table">
        <FilterTable onDelete={onDelete} />
      </div>
    </div>
  );
};

export default withRouter(FilterListPage);
