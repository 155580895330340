import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Button, Tooltip, Popconfirm } from 'antd';
import {
  DeleteOutlined,
  FormOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { getLocalDateTimeString } from '../../utils/date';

const { Search } = Input;

export const getTableConfig = ({
  tableData,
  setTableData,
  search,
  onDelete,
}) => {
  return [
    {
      title: 'Cím',
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      align: 'center',
      filterDropdown: () => (
        <div
          className="custom-filter-dropdown ant-table-filter-dropdown"
          style={{ minWidth: '20rem', padding: '0.5rem 1rem' }}
        >
          <Search
            placeholder="Íra be a keresendő szöveget"
            enterButton
            onSearch={value => search({ field: 'title', value })}
            style={{ width: 300 }}
          />
        </div>
      ),
    },
    {
      title: 'Tartalom',
      dataIndex: 'content',
      key: 'content',
      sorter: true,
      align: 'center',
      filterDropdown: () => (
        <div
          className="custom-filter-dropdown ant-table-filter-dropdown"
          style={{ minWidth: '20rem', padding: '0.5rem 1rem' }}
        >
          <Search
            placeholder="Íra be a keresendő szöveget"
            enterButton
            onSearch={value => search({ field: 'content', value })}
            style={{ width: 300 }}
          />
        </div>
      ),
    },
    {
      title: 'Pontérték',
      dataIndex: 'point',
      key: 'point',
      sorter: true,
      align: 'center',
    },
    {
      title: 'Ajándék kezdete',
      dataIndex: 'startAt',
      key: 'startAt',
      sorter: true,
      align: 'center',
      render: startAt => <span>{getLocalDateTimeString(startAt)}</span>,
    },
    {
      title: 'Ajándék vége',
      dataIndex: 'endAt',
      key: 'endAt',
      defaultSortOrder: 'ascend',
      sorter: true,
      align: 'center',
      render: endAt => <span>{getLocalDateTimeString(endAt)}</span>,
    },
    {
      title: 'Műveletek',
      key: 'action',
      render: (text, { uuid, deleted }) => (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
          }}
        >
          <Link to={`gift/${uuid}`}>
            <Tooltip title="Szerkesztés">
              <Button type="primary" icon={<FormOutlined />} size={'small'} />
            </Tooltip>
          </Link>
          <Popconfirm
            title="Biztos a törlésben"
            cancelText="Mégsem"
            okText="Igen"
            onConfirm={() =>
              onDelete({
                deleted: true,
                uuid: uuid,
                tableData,
                setTableData,
              })
            }
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
          >
            <Button type="danger" icon={<DeleteOutlined />} size={'small'} />
          </Popconfirm>
        </div>
      ),
    },
  ];
};
