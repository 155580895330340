import { message } from 'antd';

const CONTENT_TYPE_JSON = 'application/json';

export const apiFetch = (apiUrl, options) => {
  return fetch(apiUrl, options)
    .then(function(response) {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        const error = new Error(`HTTP Error ${response.statusText}`);
        error.status = response.statusText;
        error.response = response;
        throw error;
      }
    })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return data;
    });
};

export const apiGet = ({
  url,
  options = {},
  showNotificaton = false,
  download = false,
  fileName = '',
}) => {
  const defaultOptions = {
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('opinio_token'),
    },
  };
  let finalFileName = fileName;
  return fetch(url, { ...defaultOptions, ...options })
    .then(function(response) {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        const error = new Error(`HTTP Error ${response.statusText}`);
        error.status = response.statusText;
        error.response = response;
        if (showNotificaton) {
          message.error('Sikertelen művelet');
        }
        throw error;
      }
    })
    .then(function(response) {
      if (showNotificaton) {
        message.success('Sikeres művelet');
      }
      if (download) {
        const contentDisposition = response.headers.get('content-disposition');
        if (contentDisposition) {
          const attachmentName = contentDisposition
            .replace('attachment; filename="', '')
            .slice(0, -1);
          finalFileName = finalFileName || attachmentName;
        }
        return response.blob();
      }
      return response.json();
    })
    .then(function(data) {
      if (download) {
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = finalFileName;
        document.body.appendChild(a);
        a.click();
        a.remove();
      }
      return data;
    });
};

export const apiDelete = ({ url, options = {} }) => {
  const defaultOptions = {
    credentials: 'same-origin',
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('opinio_token'),
    },
  };
  return fetch(url, { ...defaultOptions, ...options })
    .then(function(response) {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        const error = new Error(`HTTP Error ${response.statusText}`);
        error.status = response.statusText;
        error.response = response;
        throw error;
      }
    })
    .then(function(response) {
      return response.json();
    })
    .then(function(data) {
      return data;
    });
};

export const apiPost = ({
  url,
  data = {},
  showNotificaton = false,
  options = {},
  contentType = CONTENT_TYPE_JSON,
}) => {
  let body = data;
  let headers = {
    Authorization: 'Bearer ' + localStorage.getItem('opinio_token'),
  };

  if (contentType === CONTENT_TYPE_JSON) {
    body = JSON.stringify(data);
    headers['Content-Type'] = CONTENT_TYPE_JSON;
  }

  const defaultOptions = {
    credentials: 'same-origin',
    method: 'POST',
    headers,
    body,
  };

  return fetch(url, { ...defaultOptions, ...options })
    .then(function(response) {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        const error = new Error(`HTTP Error ${response.statusText}`);
        error.status = response.statusText;
        error.response = response;
        if (showNotificaton) {
          message.error('Sikertelen művelet');
        }
        throw error;
      }
    })
    .then(function(response) {
      if (showNotificaton) {
        message.success('Sikeres művelet');
      }
      return response.json();
    })
    .then(function(data) {
      return data;
    });
};

export const apiPut = ({
  url,
  data = {},
  showNotificaton = false,
  options = {},
}) => {
  const defaultOptions = {
    credentials: 'same-origin',
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('opinio_token'),
    },
    body: JSON.stringify(data),
  };

  return fetch(url, { ...defaultOptions, ...options })
    .then(function(response) {
      if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        const error = new Error(`HTTP Error ${response.statusText}`);
        error.status = response.statusText;
        error.response = response;
        if (showNotificaton) {
          message.error('Sikertelen művelet');
        }
        throw error;
      }
    })
    .then(function(response) {
      if (showNotificaton) {
        message.success('Sikeres művelet');
      }
      return response.json();
    })
    .then(function(data) {
      return data;
    });
};
