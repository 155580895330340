import React from 'react';
import { Typography, Button, Menu, Dropdown } from 'antd';
import { withRouter } from 'react-router';
import { apiPut, apiGet } from '../../../utils/api';
import { SURVEY } from '../../../constants/apiPath';
import SurveyTable from '../../../components/survey-table/survey-table.component';

import './survey-list.style.css';

const { Title } = Typography;

const createSurveyMenu = history => (
  <Menu>
    <Menu.Item>
      <div onClick={() => history.push('survey/new/normal')}>
        Normál kérdőív
      </div>
    </Menu.Item>
    <Menu.Item>
      <div onClick={() => history.push('survey/new/profile')}>
        Profil kérdőív
      </div>
    </Menu.Item>
  </Menu>
);

const SurveyListPage = ({ history }) => {
  const handleDelete = async ({ uuid, tableData, setTableData }) => {
    await apiPut({
      url: `${SURVEY}/${uuid}`,
      data: { deleted: true },
      showNotificaton: true,
    });
    setTableData(tableData.filter(data => data.uuid !== uuid));
  };
  const handleSPSSDownload = async ({ uuid }) => {
    try {
      await apiGet({
        url: `${SURVEY}/${uuid}/spss`,
        showNotificaton: true,
        download: true,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleSavDownload = async ({ uuid }) => {
    try {
      await apiGet({
        url: `${SURVEY}/${uuid}/sav`,
        showNotificaton: true,
        download: true,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="dashboard-survey-page">
      <div className="dashboard-survey-page__header">
        <div className="dashboard-survey-page__header-title">
          <Title level={2}>Kérdőívek</Title>
        </div>
        <div className="dashboard-survey-page__header-btn">
          <Dropdown
            overlay={() => createSurveyMenu(history)}
            placement="bottomLeft"
            arrow
          >
            <Button type="dashed">Kérdőív hozzáadása</Button>
          </Dropdown>
        </div>
      </div>
      <div className="dashboard-user-page__table">
        <SurveyTable
          onDelete={handleDelete}
          onSPSSDownload={handleSPSSDownload}
          onSavDownload={handleSavDownload}
        />
      </div>
    </div>
  );
};

export default withRouter(SurveyListPage);
