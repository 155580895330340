export const layoutConfig = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const getFormConfig = filterList => ({
  name: 'point-form',
  items: [
    {
      type: 'inputNumber',
      name: 'point',
      label: 'Pont',
      min: 1,
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'input',
      name: 'description',
      label: 'Megjegyzés',
    },
    {
      type: 'select',
      showSearch: true,
      options: filterList,
      name: 'filterUUID',
      allowClear: true,
      label: 'Szűrő',
      optionValue: 'value',
      optionLabel: 'label',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
  ],
  submitBtnLabel: 'Küldés',
});
