import React from 'react';
import ApiTable from '../shared/api-table/api-table.component';

import { getTableConfig } from './user-coupon-table.config.js';
import { GIFT } from '../../constants/apiPath';

const UserCouponTable = ({ userUUID }) => {
  return (
    <ApiTable
      url={`${GIFT}/user/couponlist/${userUUID}`}
      getTableConfig={getTableConfig}
      defaultOrder={'descend'}
      defaultOrderField={'createdAt'}
      defaultFilterField={'content'}
      defaultFilterValue={''}
      size={'middle'}
    />
  );
};

export default UserCouponTable;
