import React from 'react';
import SimpleForm from '../shared/simple-form/simple-form.component';

import { getFormConfig, layoutConfig } from './password-reset-form.config.js';

import './password-reset-form.component.css';

const PasswordResetForm = ({ onSubmit, isLoading }) => {
  return (
    <SimpleForm
      {...getFormConfig()}
      formItemLayout={layoutConfig}
      onFinish={onSubmit}
      submitBtnLabel={'Mentés'}
      isLoading={isLoading}
    />
  );
};

export default PasswordResetForm;
