import React from 'react';
import { Button } from 'antd';
import SimpleForm from '../shared/simple-form/simple-form.component';

import { getFormConfig, layoutConfig } from './coupon-form.config.js';

import './coupon-form.style.css';

const CouponForm = ({
  onSubmit,
  isLoading,
  coupon,
  onImageLoaded,
  onCancel,
}) => {
  let config = getFormConfig({ onImageLoaded, url: coupon?.url });
  return (
    <SimpleForm
      {...config}
      formItemLayout={layoutConfig}
      onFinish={onSubmit}
      submitBtnLabel={'Mentés'}
      isLoading={isLoading}
      initialData={coupon}
      buttons={[
        <Button key={1} style={{ marginLeft: '10px' }} onClick={onCancel}>
          Mégsem
        </Button>,
      ]}
    />
  );
};

export default CouponForm;
