export const layoutConfig = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const getFormConfig = ({ onImageLoaded = () => {}, url }) => ({
  name: 'gift-form',
  items: [
    {
      type: 'input',
      name: 'title',
      label: 'Cím',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'textArea',
      name: 'content',
      label: 'Tartalom',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'imageUpload',
      name: 'cover',
      label: 'Ajándék kép',
      onImageLoaded,
      url,
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'inputNumber',
      name: 'point',
      label: 'Pont',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'datetime',
      name: 'startAt',
      label: 'Ajándék kezdete',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'datetime',
      name: 'endAt',
      label: 'Ajándék vége',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'datetime',
      name: 'validAt',
      label: 'Beváltás vége',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'select',
      showSearch: false,
      options: [
        { key: 'interval', label: 'Időszakos' },
        { key: 'quantity', label: 'Darabszámos' },
      ],
      name: 'type',
      label: 'Típus',
      optionValue: 'key',
      optionLabel: 'label',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
  ],
  submitBtnLabel: 'Mentés',
});
