import React from 'react';
import { Modal, Button, Select } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import './question-dependency.css';

const { Option } = Select;

const QuestionDependency = ({
  isModalVisible,
  handleCancel,
  questionDependencies = [],
  handleAddDependency,
  questions = [],
  handleQuestionDependencyChange,
  handleQuestionDependencyAnswerChange,
  handleDependencySave,
  handleDependencyDelete,
}) => {
  return (
    <Modal
      title="Kérdés függőségek"
      visible={isModalVisible}
      onOk={handleDependencySave}
      onCancel={handleCancel}
    >
      <div>
        {questionDependencies.map((questionDependency, index) => {
          const dependency = {
            ...questionDependency,
            answers:
              questionDependency.answers ||
              (questions &&
                questions.find(
                  q => q.uuid === questionDependency.dependency_question_uuid
                ).answers),
          };
          return (
            <div
              key={index}
              style={{
                marginBottom: '10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div style={{ flex: '0.9' }}>
                <Select
                  defaultValue={dependency.dependency_question_uuid}
                  style={{ width: '100%', marginBottom: '2px' }}
                  onChange={val => {
                    const selectedQuestion = questions.find(
                      q => q.uuid === val
                    );
                    handleQuestionDependencyChange(selectedQuestion, index);
                  }}
                >
                  {questions.map(question => {
                    return (
                      <Option key={question.uuid} value={question.uuid}>
                        {question.content}
                      </Option>
                    );
                  })}
                </Select>
                <Select
                  defaultValue={dependency?.dependency_answer_uuid}
                  style={{ width: '100%' }}
                  onChange={val => {
                    const selectedAnswer = dependency.answers.find(
                      ({ uuid }) => uuid === val
                    );
                    handleQuestionDependencyAnswerChange(selectedAnswer, index);
                  }}
                >
                  {dependency.answers.map(answer => {
                    return (
                      <Option key={answer.uuid} value={answer.uuid}>
                        {answer.content}
                      </Option>
                    );
                  })}
                </Select>
              </div>
              <div className="question-dependency__delete-icon">
                <DeleteOutlined
                  onClick={() => handleDependencyDelete(dependency)}
                />
              </div>
            </div>
          );
        })}
        <Button onClick={handleAddDependency}>Függőség felvétele</Button>
      </div>
    </Modal>
  );
};

export default QuestionDependency;
