import React from 'react';
import { Typography, Select } from 'antd';
import './vote-selector.style.css';
const { Option } = Select;
const { Text } = Typography;
const VoteSelector = ({
  handleSurveyChange,
  handleQuestionChange,
  handleAnswerChange,
  filterValue = {},
  idx,
  surveys,
}) => {
  return (
    <div className="vote-selector">
      <div className="vote-selector__field">
        <Text className="vote-selector__label">Kérdőív:</Text>
        <Select
          showSearch
          className="vote-select"
          placeholder="Kérdőívek"
          defaultValue={filterValue.surveyUUID}
          optionFilterProp="children"
          onChange={uuid => handleSurveyChange({ uuid, idx })}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {surveys.map(survey => (
            <Option key={survey.uuid} value={survey.uuid}>
              {survey.title}
            </Option>
          ))}
        </Select>
      </div>
      {filterValue?.questions && (
        <div className="vote-selector__field">
          <Text className="vote-selector__label">Kérdés:</Text>
          <Select
            showSearch
            className="vote-select"
            placeholder="Kérdések"
            defaultValue={filterValue.questionId}
            optionFilterProp="children"
            onChange={id => handleQuestionChange({ id, idx })}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {filterValue.questions.map(question => (
              <Option key={question.id} value={question.id}>
                {question.content}
              </Option>
            ))}
          </Select>
        </div>
      )}
      {filterValue?.answers && (
        <div className="vote-selector__field">
          <Text className="vote-selector__label">Válasz:</Text>
          <Select
            showSearch
            className="vote-select"
            placeholder="Válaszok"
            defaultValue={filterValue.answerId}
            optionFilterProp="children"
            onChange={id => handleAnswerChange({ id, idx })}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {filterValue.answers.map(answer => (
              <Option key={answer.id} value={answer.id}>
                {answer.content}
              </Option>
            ))}
          </Select>
        </div>
      )}
    </div>
  );
};

export default VoteSelector;
