import React, { useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Switch,
  Checkbox,
  DatePicker,
} from 'antd';
import ImageUpload from '../image-upload/image-upload.component';

const { TextArea } = Input;
const { Option } = Select;

const SimpleForm = ({
  name = '',
  formItemLayout = {},
  onFinish = values => console.log(values),
  items = [],
  submitBtnLabel = '',
  isLoading = false,
  initialData = {},
  buttons = [],
  resetFields = false,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initialData && Object.keys(initialData).length > 0) {
      form.setFieldsValue(initialData);
    }
  }, [initialData]);

  const handleSubmit = async fields => {
    await onFinish(fields);
    if (resetFields) {
      form.resetFields();
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name={name}
      onFinish={handleSubmit}
      scrollToFirstError
      initialValues={initialData}
    >
      {items.map(item => {
        switch (item.type) {
          case 'input':
            return renderInput(item);
          case 'inputNumber':
            return renderInputNumber(item);
          case 'textArea':
            return renderTextArea(item);
          case 'password':
            return renderInputPassword(item);
          case 'checkbox':
            return renderCheckbox(item);
          case 'select':
            return renderSelect(item);
          case 'multiSelect':
            return renderMultiSelect(item);
          case 'switch':
            return renderSwitch(item);
          case 'datetime':
            return renderDatePicker(item);
          case 'imageUpload':
            return renderImageUpload(item);
          default:
            return <React.Fragment key={item.name} />;
        }
      })}
      <div className="simple-form__btn-container">
        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          data-testid="simple-form-btn"
        >
          {submitBtnLabel}
        </Button>
        {buttons}
      </div>
    </Form>
  );
};

const renderInput = ({ name, label, rules = [], placeholder }) => (
  <Form.Item key={name} name={name} label={label} rules={rules}>
    <Input placeholder={placeholder} />
  </Form.Item>
);

const renderInputNumber = ({ name, label, rules = [], min = 0 }) => (
  <Form.Item key={name} name={name} label={label} rules={rules}>
    <InputNumber min={min} />
  </Form.Item>
);

const renderTextArea = ({ name, label, rules = [] }) => (
  <Form.Item key={name} name={name} label={label} rules={rules}>
    <TextArea rows={4} />
  </Form.Item>
);

const renderSwitch = ({ name, label }) => {
  return (
    <Form.Item key={name} name={name} label={label} valuePropName={'checked'}>
      <Switch />
    </Form.Item>
  );
};

const renderCheckbox = ({ name, label }) => (
  <Form.Item key={name} name={name} label={label} valuePropName={'checked'}>
    <Checkbox />
  </Form.Item>
);

const renderInputPassword = ({ name, label, rules = [] }) => (
  <Form.Item key={name} name={name} label={label} rules={rules}>
    <Input.Password />
  </Form.Item>
);

const renderDatePicker = ({
  name,
  label,
  rules = [],
  format = 'YYYY-MM-DD HH:mm',
}) => (
  <Form.Item key={name} name={name} label={label} rules={rules}>
    <DatePicker showTime format={format} />
  </Form.Item>
);

const renderSelect = ({
  name = '',
  label = '',
  showSearch = true,
  allowClear = false,
  optionFilterProp = 'children',
  filterOption = (input, option) =>
    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
  options = [],
  optionValue = '',
  optionLabel = '',
  rules = [],
  defaultValue = '',
  disabled = false,
}) => (
  <Form.Item key={name} name={name} label={label} rules={rules}>
    <Select
      showSearch={showSearch}
      optionFilterProp={optionFilterProp}
      filterOption={filterOption}
      allowClear={allowClear}
      defaultValue={defaultValue}
      disabled={disabled}
    >
      {options.map(option => (
        <Option key={option[optionValue]} value={option[optionValue]}>
          {option[optionLabel]}
        </Option>
      ))}
    </Select>
  </Form.Item>
);

const renderMultiSelect = ({
  name = '',
  label = '',
  options = [],
  defaultValue = [],
  rules = [],
}) => (
  <Form.Item key={name} name={name} label={label} rules={rules}>
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      placeholder="Please select"
      options={options}
    />
  </Form.Item>
);

const renderImageUpload = ({ name, label, onImageLoaded, url }) => (
  <Form.Item key={name} name={name} label={label}>
    <ImageUpload onImageLoaded={onImageLoaded} url={url} />
  </Form.Item>
);

export default SimpleForm;
