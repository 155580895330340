import React from 'react';
import { Typography, Button } from 'antd';
import { withRouter } from 'react-router';
import { apiPut } from '../../../utils/api';
import { TAG } from '../../../constants/apiPath';

import TagTable from '../../../components/tag-table/tag-table.component';

import './tag-list.style.css';

const { Title } = Typography;

const TagListPage = ({ history }) => {
  const onDelete = async ({ uuid, content, tableData, setTableData }) => {
    try {
      await apiPut({
        url: `${TAG}/${uuid}`,
        data: { deleted: true, content },
        showNotificaton: true,
      });
      setTableData(tableData.filter(data => data.uuid !== uuid));
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="dashboard-tag-page">
      <div className="dashboard-tag-page__header">
        <div className="dashboard-tag-page__header-title">
          <Title level={2}>Címkék</Title>
        </div>
        <div className="dashboard-tag-page__header-btn">
          <Button type="dashed" onClick={() => history.push('tag/new')}>
            Címke hozzáadása
          </Button>
        </div>
      </div>
      <div className="dashboard-tag-page__table">
        <TagTable onDelete={onDelete} />
      </div>
    </div>
  );
};

export default withRouter(TagListPage);
