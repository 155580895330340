import React from 'react';
import SimpleForm from '../shared/simple-form/simple-form.component';

import { getFormConfig, layoutConfig } from './login-form.config.js';

import './login-form.component.css';

const LoginForm = ({ onSubmit, isLoading }) => {
  return (
    <SimpleForm
      {...getFormConfig()}
      formItemLayout={layoutConfig}
      onFinish={onSubmit}
      submitBtnLabel={'Belépés'}
      isLoading={isLoading}
    />
  );
};

export default LoginForm;
