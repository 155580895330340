export const layoutConfig = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const getFormConfig = filterList => ({
  name: 'push-form',
  items: [
    {
      type: 'input',
      name: 'notificationTitle',
      label: 'Cím',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'textArea',
      name: 'notificationMessage',
      label: 'Tartalom',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'input',
      name: 'url',
      label: 'Url',
      rules: [
        ({ getFieldValue }) => ({
          validator(_, value) {
            const isUrl = value
              ? /^(?:\w+:)?\/\/([^\s\.]+\.\S{2}|localhost[\:?\d]*)\S*$/.test(
                  value
                )
              : false;
            if (!value || isUrl) {
              return Promise.resolve();
            }

            return Promise.reject(new Error('Url is not valid!'));
          },
        }),
      ],
    },
    {
      type: 'select',
      showSearch: true,
      options: filterList,
      name: 'filterUUID',
      allowClear: true,
      label: 'Szűrő',
      optionValue: 'value',
      optionLabel: 'label',
      rules: [
        {
          required: false,
          message: 'Kötelező mező',
        },
      ],
    },
  ],
  submitBtnLabel: 'Küldés',
});
