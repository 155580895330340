import React, { useState, useEffect } from 'react';
import { Typography } from 'antd';
import { apiPost, apiGet } from '../../../utils/api';
import { USER, USER_FILTER } from '../../../constants/apiPath';

import PointForm from '../../../components/point-form/point-form.component';
import './point.style.css';
const { Title } = Typography;

const PointPage = () => {
  const [pointSubmitInProgress, setPointSubmitInProgress] = useState(false);
  const title = 'Csoportos pont adás';
  const [filterOptions, setFilterOptions] = useState([]);

  const submitPoint = async ({ filterUUID, point, description }) => {
    try {
      setPointSubmitInProgress(true);
      await apiPost({
        url: `${USER}/custompoint/filter/${filterUUID}`,
        data: {
          description,
          point,
          type: 'admin-group-point',
        },
        showNotificaton: true,
      });
    } catch (err) {
      console.log(err);
    } finally {
      setPointSubmitInProgress(false);
    }
  };

  useEffect(() => {
    const fetchFilters = async () => {
      const filterList = await apiGet({ url: `${USER_FILTER}/all` });
      setFilterOptions(
        filterList.map(filter => ({ value: filter.uuid, label: filter.name }))
      );
    };
    fetchFilters();
  }, []);

  return (
    <div className="dashboard-point-page">
      <div className="dashboard-point-page__header">
        <div className="dashboard-point-page__header-title">
          <Title level={2}>{title}</Title>
        </div>
      </div>
      <div className="dashboard-point-page__container">
        <PointForm
          onSubmit={submitPoint}
          isLoading={pointSubmitInProgress}
          filterList={filterOptions}
        />
      </div>
    </div>
  );
};

export default PointPage;
