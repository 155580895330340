import React, { useState } from 'react';
import { Modal, Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { apiPost } from '../../utils/api';
import { COUPON_BATCH_UPLOAD } from '../../constants/apiPath';

import './coupon-upload-dialog.css';

const CouponUploadDialog = ({
  isModalVisible = false,
  handleCancel = () => ({}),
  giftUUID = '',
  handleUploadSuccess = () => ({}),
}) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = async () => {
    const formData = new FormData();
    formData.append('file', fileList[0], fileList[0].name);

    try {
      setUploading(true);
      await apiPost({
        url: `${COUPON_BATCH_UPLOAD}/${giftUUID}`,
        data: formData,
        showNotificaton: true,
        contentType: 'multipart/form-data',
      });
      handleUploadSuccess();
    } catch (error) {
      console.log(error);
    } finally {
      setUploading(false);
    }
  };

  const handleRemove = file => {
    const index = fileList.indexOf(file);
    const newFileList = fileList.slice();
    newFileList.splice(index, 1);
    setFileList(newFileList);
  };

  const beforeUpload = file => {
    setFileList([file]);
    return false;
  };

  return (
    <Modal
      title="Kuponok feltöltése"
      visible={isModalVisible}
      onCancel={handleCancel}
      okButtonProps={{ style: { display: 'none' } }}
    >
      {' '}
      <p>Fájl feltöltés:</p>
      <Upload
        onRemove={handleRemove}
        beforeUpload={beforeUpload}
        fileList={fileList}
      >
        <Button icon={<UploadOutlined />}>Fájl kiválasztása</Button>
      </Upload>
      <Button
        type="primary"
        onClick={handleUpload}
        disabled={fileList.length === 0}
        loading={uploading}
        style={{ marginTop: 16 }}
      >
        {uploading ? 'Feltöltés folyamatban' : 'Feltöltés indítása'}
      </Button>
    </Modal>
  );
};

export default CouponUploadDialog;
