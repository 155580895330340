import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Typography, Breadcrumb } from 'antd';
import { apiPost, apiGet, apiPut } from '../../../utils/api';
import { TAG } from '../../../constants/apiPath';

import TagForm from '../../../components/tag-form/tag-form.component';
import PageLoader from '../../../components/shared/page-loader/page-loader.component';

const { Title } = Typography;

const TagDetailPage = ({ match, history }) => {
  const [tagSubmitInProgress, setTagSubmitInProgress] = useState(false);
  const [tagFetchInProgress, setTagFetchInProgress] = useState(false);
  const [tag, setTag] = useState(null);

  const uuid = match.params.uuid;
  const title = uuid ? 'Címke szerkesztés' : 'Címke létrehozás';

  const submitTag = async tag => {
    try {
      setTagSubmitInProgress(true);
      if (uuid) {
        await apiPut({
          url: `${TAG}/${uuid}`,
          data: tag,
          showNotificaton: true,
        });
      } else {
        const newTag = await apiPost({
          url: TAG,
          data: tag,
          showNotificaton: true,
        });
        history.push(`${newTag.uuid}`);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setTagSubmitInProgress(false);
    }
  };

  const fetchTag = async () => {
    try {
      setTagFetchInProgress(true);
      const tag = await apiGet({ url: `${TAG}/${uuid}` });
      setTag(tag);
    } catch (err) {
      console.log(err);
    } finally {
      setTagFetchInProgress(false);
    }
  };

  useEffect(() => {
    if (uuid && !tag) {
      fetchTag();
    }
  }, []);
  if (tagFetchInProgress) {
    return <PageLoader />;
  }
  return (
    <div className="dashboard-tag-detail-page">
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to={'/dashboard/tag'}>Címkék</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{title}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="dashboard-tag-detail-page__header">
        <div className="dashboard-tag-detail-page__header-title">
          <Title level={2}>{title}</Title>
        </div>
      </div>
      <div className="dashboard-survey-detail-page__tab">
        <TagForm
          onSubmit={submitTag}
          isLoading={tagSubmitInProgress}
          tag={tag}
        />
      </div>
    </div>
  );
};

export default withRouter(TagDetailPage);
