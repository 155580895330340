import React from 'react';
import ApiTable from '../shared/api-table/api-table.component';

import { getTableConfig } from './custom-point-table.config.js';
import { USER } from '../../constants/apiPath';

const CustomPointTable = ({ userUUID, onDelete }) => {
  return (
    <ApiTable
      url={`${USER}/custompoints/${userUUID}`}
      getTableConfig={getTableConfig}
      defaultOrder={'descend'}
      defaultOrderField={'createdAt'}
      defaultFilterField={'description'}
      defaultFilterValue={''}
      size={'middle'}
      actions={{
        onDelete,
      }}
    />
  );
};

export default CustomPointTable;
