import React, { useState, useEffect } from 'react';
import { Table } from 'antd';
import { apiPost } from '../../../utils/api';

import './api-table.styles.css';

const ApiTable = ({
  url = '',
  getTableConfig,
  actions = {},
  defaultOrder,
  defaultOrderField,
  defaultFilterField,
  defaultFilterValue,
  size,
}) => {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPagination, setCurrentPagination] = useState({
    order: defaultOrder,
    orderField: defaultOrderField,
  });
  const [filter, setFilter] = useState({
    field: defaultFilterField,
    value: defaultFilterValue,
  });
  const columns = getTableConfig({
    tableData,
    setTableData,
    search: filter => {
      setFilter(filter);
      getData({
        pageSize: 10,
        page: currentPagination.current - 1,
        order: currentPagination.order === 'ascend' ? 'asc' : 'desc',
        orderField: currentPagination.orderField,
        filterField: filter.field,
        filterValue: filter.value,
      });
    },
    ...actions,
  });

  const getData = async ({
    pageSize,
    page,
    order,
    orderField,
    filterField,
    filterValue,
  }) => {
    try {
      setLoading(true);
      const data = await apiPost({
        url,
        data: {
          pageSize,
          page,
          order,
          orderField,
          filterField,
          filterValue,
        },
      });
      setTableData(
        data.results.map((result, index) => ({ ...result, key: index }))
      );
      setCurrentPagination({ ...currentPagination, total: data.total });
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (pagination, filters, sorter) => {
    const { order, field: orderField } = sorter;
    setCurrentPagination({ ...currentPagination, current: pagination.current });
    getData({
      pageSize: pagination.pageSize,
      page: pagination.current - 1,
      order: order === 'ascend' ? 'asc' : 'desc',
      orderField: orderField,
      filterField: filter.field,
      filterValue: filter.value,
    });
  };

  useEffect(() => {
    getData({
      page: 0,
      pageSize: 10,
      order: defaultOrder === 'ascend' ? 'asc' : 'desc',
      orderField: defaultOrderField,
      filterField: defaultFilterField,
      filterValue: defaultFilterValue,
    });
  }, []);
  return (
    <Table
      data-testid="api-table"
      className="api-table"
      dataSource={tableData}
      pagination={currentPagination}
      columns={columns}
      size={size}
      loading={loading}
      onChange={handleTableChange}
    />
  );
};

export default ApiTable;
