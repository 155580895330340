export const layoutConfig = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 6,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const getFormConfig = () => ({
  name: 'login-form',
  items: [
    {
      type: 'input',
      name: 'email',
      label: 'Email',
      rules: [
        {
          required: true,
          message: 'Please input your E-mail!',
        },
      ],
    },
    {
      type: 'password',
      name: 'password',
      label: 'Jelszó',
      rules: [{ required: true, message: 'Kötelező mező' }],
    },
  ],
  submitBtnLabel: 'Submit',
});
