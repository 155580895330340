import './user-list.page.style.css';

import { Button, Typography } from 'antd';
import { apiDelete, apiGet } from '../../../utils/api';

import React from 'react';
import { USER } from '../../../constants/apiPath';
import UserTable from '../../../components/user-table/user-table.component';
import { getLocalDateTimeString } from '../../../utils/date';
import { withRouter } from 'react-router';

const { Title } = Typography;

const UserListPage = ({ history }) => {
  const handleDelete = async ({ uuid, tableData, setTableData }) => {
    try {
      await apiDelete({
        url: `${USER}/${uuid}`,
        showNotificaton: true,
      });
      setTableData(tableData.filter(data => data.uuid !== uuid));
    } catch (err) {
      console.log(err);
    }
  };
  const handleExport = async () => {
    try {
      await apiGet({
        url: `${USER}/csv`,
        showNotificaton: true,
        download: true,
        fileName: `users_${getLocalDateTimeString()}.csv`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleUserInactivityExport = async () => {
    try {
      await apiGet({
        url: `${USER}/csv/reports/userInactivity`,
        showNotificaton: true,
        download: true,
        fileName: `user_inactivity_${getLocalDateTimeString()}.csv`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  const handleUserPushOpenExport = async () => {
    try {
      await apiGet({
        url: `${USER}/csv/reports/pushOpen`,
        showNotificaton: true,
        download: true,
        fileName: `user_push_open_${getLocalDateTimeString()}.csv`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="dashboard-user-page">
      <div className="dashboard-user-page__header">
        <div className="dashboard-user-page__header-title">
          <Title level={2}>Felhasználók</Title>
        </div>
        <div className="dashboard-user-page__header-btn">
          <Button type="dashed" onClick={() => history.push('users/new')}>
            Felhasználó hozzáadása
          </Button>
          <Button
            type="dashed"
            onClick={handleExport}
            className="dashboard-user-page__header-btn-export"
          >
            Felhasználók exportálása
          </Button>
          <Button
            type="dashed"
            onClick={handleUserInactivityExport}
            className="dashboard-user-page__header-btn-export"
          >
            Inaktív felhasználók
          </Button>
          <Button
            type="dashed"
            onClick={handleUserPushOpenExport}
            className="dashboard-user-page__header-btn-export"
          >
            Felhasználók push statisztika
          </Button>
        </div>
      </div>
      <div className="dashboard-user-page__table">
        <UserTable onDelete={handleDelete} />
      </div>
    </div>
  );
};

export default withRouter(UserListPage);
