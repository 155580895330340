import React from 'react';
import SimpleForm from '../shared/simple-form/simple-form.component';

import { getFormConfig, layoutConfig } from './tag-form.config.js';

import './tag-form.style.css';

const TagForm = ({ onSubmit, isLoading, tag }) => {
  let config = getFormConfig();
  return (
    <SimpleForm
      {...config}
      formItemLayout={layoutConfig}
      onFinish={onSubmit}
      submitBtnLabel={'Mentés'}
      isLoading={isLoading}
      initialData={tag}
    />
  );
};

export default TagForm;
