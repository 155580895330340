import React from 'react';
import ApiTable from '../shared/api-table/api-table.component';

import { getTableConfig } from './gift-table.config.js';
import { GIFT_LIST } from '../../constants/apiPath';

const GiftTable = ({ onDelete }) => {
  return (
    <ApiTable
      url={GIFT_LIST}
      getTableConfig={getTableConfig}
      defaultOrder={'ascend'}
      defaultOrderField={'endAt'}
      defaultFilterField={'title'}
      defaultFilterValue={''}
      size={'middle'}
      actions={{
        onDelete,
      }}
    />
  );
};

export default GiftTable;
