export const layoutConfig = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const getFormConfig = () => ({
  name: 'custom-point-form',
  items: [
    {
      type: 'inputNumber',
      name: 'point',
      label: 'OPont',
      min: 1,
    },
    {
      type: 'input',
      name: 'description',
      label: 'Megjegyzés',
    },
  ],
  submitBtnLabel: 'Mentés',
});
