import React from 'react';
import ApiTable from '../shared/api-table/api-table.component';

import { getTableConfig } from './filter-table.config.js';
import { USER_FILTER_LIST } from '../../constants/apiPath';

const FilterTable = ({ onDelete }) => {
  return (
    <ApiTable
      url={USER_FILTER_LIST}
      getTableConfig={getTableConfig}
      defaultOrder={'ascend'}
      defaultOrderField={'createdAt'}
      defaultFilterField={'name'}
      defaultFilterValue={''}
      size={'middle'}
      actions={{
        onDelete,
      }}
    />
  );
};

export default FilterTable;
