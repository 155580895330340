import React from 'react';
import SimpleForm from '../shared/simple-form/simple-form.component';

import { getFormConfig, layoutConfig } from './point-form.config.js';

import './point-form.style.css';

const PointForm = ({ onSubmit, isLoading, filterList }) => {
  let config = getFormConfig(filterList);
  return (
    <SimpleForm
      {...config}
      formItemLayout={layoutConfig}
      onFinish={onSubmit}
      submitBtnLabel={'Pontok hozzáadása'}
      isLoading={isLoading}
      resetFields={true}
    />
  );
};

export default PointForm;
