import React from 'react';
import SimpleForm from '../shared/simple-form/simple-form.component';

import { getFormConfig, layoutConfig } from './user-form.config.js';

import './user-form.component.css';

const UserForm = ({ onSubmit, isLoading, user }) => {
  let config = getFormConfig();
  // filter out role and password field not to able to edit, password only for admin
  if (user) {
    if (user.role === 'ADMIN') {
      config.items = config.items
        .filter(item => item.name !== 'role')
        .map(item => {
          if (item.name === 'password') {
            return { ...item, rules: [] };
          }
          return { ...item };
        });
    } else {
      config.items = config.items.filter(
        item => item.name !== 'role' && item.name !== 'password'
      );
    }
  }
  return (
    <SimpleForm
      {...config}
      formItemLayout={layoutConfig}
      onFinish={onSubmit}
      submitBtnLabel={'Mentés'}
      isLoading={isLoading}
      initialData={user}
    />
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.user === nextProps.user &&
    prevProps.isLoading === nextProps.isLoading
  );
};

export default React.memo(UserForm, areEqual);
