import React from 'react';
import ApiTable from '../shared/api-table/api-table.component';

import { getTableConfig } from './survey-table.config.js';
import { SURVEY_LIST } from '../../constants/apiPath';

const SurveyTable = ({ onDelete, onSPSSDownload, onSavDownload }) => {
  return (
    <ApiTable
      url={SURVEY_LIST}
      getTableConfig={getTableConfig}
      defaultOrder={'ascend'}
      defaultOrderField={'title'}
      defaultFilterField={'title'}
      defaultFilterValue={''}
      size={'middle'}
      actions={{
        onDelete,
        onSPSSDownload,
        onSavDownload,
      }}
    />
  );
};

export default SurveyTable;
