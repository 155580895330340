import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import './list-add-card.style.css';

const ListAddCard = ({ onClick }) => {
  return (
    <div
      onClick={onClick}
      className="list-add-card"
      data-testid="list-add-card"
    >
      <PlusOutlined />
    </div>
  );
};

export default ListAddCard;
