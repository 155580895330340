import React, { createContext, useReducer } from 'react';

export const USER_AUTHENTICATED = 'USER_AUTHENTICATED';
export const USER_UNAUTHENTICATED = 'USER_UNAUTHENTICATED';

const initialState = {
  isAuthenticated: false,
};
const store = createContext(initialState);

const { Provider } = store;

const UserProvider = ({ children }) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case USER_AUTHENTICATED:
        return { ...state, isAuthenticated: true };
      case USER_UNAUTHENTICATED:
        return { ...state, isAuthenticated: false };
      default:
        return state;
    }
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, UserProvider };
