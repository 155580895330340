import React, { useState, useEffect } from 'react';
import { Upload, message, Button, Popconfirm } from 'antd';
import {
  UploadOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import { getBase64 } from '../../../utils/base64';

function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('You can only upload JPG/PNG file!');
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('Image must smaller than 2MB!');
  }
  return isJpgOrPng && isLt2M;
}

const ImageUpload = ({ onImageLoaded, url = '', handleDelete = () => {} }) => {
  const [imageUrls, setImageUrls] = useState([]);

  useEffect(() => {
    if (url) {
      setImageUrls([url]);
    }
    return function cleanup() {
      setImageUrls([]);
    };
  }, [url]);

  const handleLoad = info => {
    getBase64(info.file, imageUrl => {
      setImageUrls([...imageUrls, imageUrl]);
    });
    onImageLoaded(info.file);
  };

  return (
    <div>
      <Upload
        name="image-upload"
        listType="picture"
        showUploadList={false}
        multiple={true}
        beforeUpload={beforeUpload}
        customRequest={info => handleLoad(info)}
      >
        {imageUrls && imageUrls.length > 0 ? (
          imageUrls.map(imageUrl => {
            return (
              <img
                src={imageUrl}
                alt="file upload"
                style={{ maxWidth: '200px', maxHeight: '200px' }}
              />
            );
          })
        ) : (
          <Button>
            <UploadOutlined /> Feltöltés
          </Button>
        )}
      </Upload>
      {imageUrls && (
        <Popconfirm
          title="Biztos a törlésben"
          cancelText="Mégsem"
          okText="Igen"
          onConfirm={() => {
            setImageUrls([]);
            handleDelete();
          }}
          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
        >
          <Button
            type="danger"
            style={{ marginLeft: '10px' }}
            icon={<DeleteOutlined />}
            size={'small'}
          />
        </Popconfirm>
      )}
    </div>
  );
};

export default ImageUpload;
