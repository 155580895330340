export const layoutConfig = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const getNormalFormConfig = (tagList, filterList) => ({
  name: 'survey-form',
  items: [
    {
      type: 'input',
      name: 'title',
      label: 'Cím',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'textArea',
      name: 'summary',
      label: 'Megjegyzés',
    },
    {
      type: 'datetime',
      name: 'publishedAt',
      label: 'Publikálás ideje',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'datetime',
      name: 'endAt',
      label: 'Lejárat ideje',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'select',
      showSearch: false,
      options: [{ key: 'normal', label: 'Normál' }],
      name: 'type',
      label: 'Típus',
      defaultValue: 'normal',
      disabled: true,
      optionValue: 'key',
      optionLabel: 'label',
    },
    {
      type: 'select',
      showSearch: true,
      options: filterList,
      name: 'user_filter_id',
      label: 'Szűrő',
      optionValue: 'value',
      optionLabel: 'label',
      rules: [
        {
          required: false,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'input',
      name: 'notificationTitle',
      label: 'Push üzenet cím',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'textArea',
      name: 'notificationMessage',
      label: 'Push üzenet tartalom',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'input',
      name: 'webTitle',
      label: 'Cím webre',
    },
    {
      type: 'multiSelect',
      options: tagList,
      name: 'tags',
      label: 'Címke',
    },
    {
      type: 'checkbox',
      name: 'random',
      label: 'Random kérdés sorrend',
    },
  ],
  submitBtnLabel: 'Mentés',
});

export const getProfileFormConfig = (tagList, filterList) => ({
  name: 'survey-form',
  items: [
    {
      type: 'input',
      name: 'title',
      label: 'Cím',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'textArea',
      name: 'summary',
      label: 'Megjegyzés',
    },
    {
      type: 'select',
      showSearch: false,
      options: [{ key: 'profile', label: 'Profil' }],
      defaultValue: 'profile',
      disabled: true,
      name: 'type',
      label: 'Típus',
      optionValue: 'key',
      optionLabel: 'label',
    },
    {
      type: 'select',
      showSearch: true,
      options: filterList,
      name: 'user_filter_id',
      label: 'Szűrő',
      optionValue: 'value',
      optionLabel: 'label',
      rules: [
        {
          required: false,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'multiSelect',
      options: tagList,
      name: 'tags',
      label: 'Címke',
    },
  ],
  submitBtnLabel: 'Mentés',
});
