export const layoutConfig = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};

export const getFormConfig = () => ({
  name: 'user-form',
  items: [
    {
      type: 'input',
      name: 'lastName',
      label: 'Vezetéknév',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'input',
      name: 'firstName',
      label: 'Keresztnév',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'input',
      name: 'phone',
      label: 'Telefonszám',
    },
    {
      type: 'input',
      name: 'email',
      label: 'Email cím',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'select',
      showSearch: false,
      options: [{ key: 'ADMIN', label: 'Admin' }],
      name: 'role',
      label: 'Jogosultság',
      optionValue: 'key',
      optionLabel: 'label',
      rules: [
        {
          required: true,
          message: 'Kötelező mező',
        },
      ],
    },
    {
      type: 'password',
      name: 'password',
      label: 'Jelszó',
      rules: [{ required: true, message: 'Kötelező mező' }],
    },
  ],
  submitBtnLabel: 'Submit',
});
